/*===================== 
	Primary bg color 
=====================*/

/* Hex */
#loading-area > div,
.bg-primary,
a.bg-primary:hover,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.site-button,
.lg-actions .lg-next, 
.lg-actions .lg-prev, 
.lg-sub-html, 
.lg-toolbar{
    background-color: #5362ff;
}

/*Hex color :hover */
.site-button:active,
.site-button:hover,
.site-button:focus,
.active > .site-button,
.bg-primary-dark,
.sidenav .closebtn:hover,
.style-2 .dez-social-icon.dez-border li a:hover{
    background-color: #5462ff;
}

/*===================== 
	Premery text color 
=====================*/
a,
.text-primary,
.site-button.outline,
.dez-countdown .date .time,
.lg-outer .lg-toogle-thumb,
.lg-outer .lg-toogle-thumb:hover {
    color: #5362ff;
}

/*===================== 
	Border Color color 
=====================*/
.site-button.outline,
.dez-countdown .date .time{
	border-color:#5362ff;
}

.lg-outer .lg-thumb-item.active, 
.lg-outer .lg-thumb-item:hover{
	border-color: #5362ff;
}

/* GD */

.style-1 .countdown .date .time{
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e79ff+0,5462ff+100 */
	background: rgb(46,121,255); /* Old browsers */
	background: -moz-linear-gradient(left, rgba(46,121,255,1) 0%, rgba(84,98,255,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba(46,121,255,1) 0%,rgba(84,98,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba(46,121,255,1) 0%,rgba(84,98,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e79ff', endColorstr='#5462ff',GradientType=1 ); /* IE6-9 */
}
.galaxy-box .count-1.countdown .date .time,
.style-1 .dez-social-icon li a,
.site-button.gradient{
    background-image: linear-gradient(to right,#2e79ff 0,#5462ff 51%,#2e79ff 100%);
	background-size: 200%;
}
.style-1 .dez-social-icon li a:hover,
.site-button.gradient:hover{
    background-position: right center;
}
#loading-area > div,
.site-button.shadow{
	box-shadow: 10px 10px 30px 0 rgba(47,120,255,0.25) !important;
}




